import Highlights from "components/common/Highlights";
import CTABanner from "components/common/footer-banners/CTABanner";
import HeroLayout5 from "components/common/hero/HeroLayout5";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import GlobalLayout from "components/page/GlobalLayout";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function physicalSecurityChecklist() {
  const renderContent = data => {
    const highlightSection = {
      title: "What You’ll Learn",
      image: data.highlightImage,
      list: [
        {
          title: "Modern Best Practices",
          description:
            "Measure your existing security operations against current best practices. ",
        },
        {
          title: "Step-by-Step Guide ",
          description:
            "Assess your security quickly with a straightforward checklist and grading scale.",
        },
        {
          title: "Assess Multiple Systems",
          description:
            "Review the key systems of modern physical security: cameras, access control, alarms, and integrations.",
        },
      ],
    };

    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>
            Physical Security Checklist: Take Your Security to the Next Level
          </title>
          <meta
            name="description"
            content="How secure is your organization? In today's ever-evolving security landscape, keeping up with current best practices is crucial. Use this checklist to perform a fast and comprehensive review of your security operations, including cameras, access control, and alarms."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <HeroLayout5
          pretTitle="RESOURCE GUIDE"
          title="Physical Security Checklist: Take Your Security to the Next Level"
          blurb="How secure is your organization? In today's ever-evolving security landscape, keeping up with current best practices is crucial. Use this checklist to perform a fast and comprehensive review of your security operations, including cameras, access control, and alarms."
          formId="c03cb329-b9b3-4162-9506-1358b01afe81"
          formTitle="Download the Resource Guide"
        />
        <Highlights data={highlightSection} center color="var(--white)" />
        <IndustryLeadersCards color="var(--nuetral-100)" />
        <CTABanner />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      highlightImage: file(
        relativePath: {
          eq: "components/landing-pages/img/security-report-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;
  return <StaticQuery render={renderContent} query={GET_IMAGES} />;
}
